import TrackJS from '@fairstone-frontend/utils/core/logs/trackjs';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { isEmpty } from 'lodash';

import { currentFlow, currentFlowEnum, currentPage, FlowEnum, setCurrentStep } from 'routes/FlowManager';
import { ScreenUrlPathsEnum, StepNameEnum } from 'routes/types';
import { restAPI } from 'services/api';
import { setAddress } from 'store/redux/modules/address';
import {
    setAdplacementId,
    setApplicationFlow,
    setApplicationNumber,
    setBranchNumber,
    setChannelCode,
    setLatestStep,
} from 'store/redux/modules/application';
import { updateDemographic } from 'store/redux/modules/demographic';
import { setRequiredDocuments } from 'store/redux/modules/documentVerification';
import { getLeadOffersAsync, getOffersAsync, setIsGloOffer, setVisionAccountNumber } from 'store/redux/modules/offers';
import { OfferRequest } from 'store/redux/modules/offers/types';
import { ApplicationStatusEnum } from 'store/redux/modules/types';
import { RootState } from 'types/store';
import { findStepNameByURL, isFurthestStep } from 'utils/routes';

import {
    INonGloApplicationRequest,
    INonGloApplicationResponse,
    IRposApplicationRequest,
    IRposApplicationResponse,
    IUserLastState,
} from './types';

interface IGetLatestStep {
    offerRequest: OfferRequest;
    forceIsGlo: boolean | null;
}

export const getLatestStep = async (): Promise<AxiosResponse | null> => {
    let response: AxiosResponse | null = null;

    try {
        response = await restAPI.get('/step/latest');
    } catch (error: any) {
        TrackJS.track(error);
    }
    return response;
};

export const getRposLatestStepAsync = createAsyncThunk('/step/rpos/latest', async (_, { dispatch }) => {
    await dispatch(getLeadOffersAsync());

    dispatch(setIsGloOffer(true));
    dispatch(setApplicationFlow(FlowEnum.RPOS));

    const response = await getLatestStep();

    if (response?.data?.state) {
        const { address, application, demographic, offers } = response.data.state;
        if (application.channelCode) {
            dispatch(setChannelCode(application.channelCode));
        }
        if (application.adplacementId) {
            dispatch(setAdplacementId(application.adplacementId));
        }
        if (application.applicationNumber) {
            dispatch(setApplicationNumber(application.applicationNumber));
        }
        if (demographic) {
            dispatch(updateDemographic(demographic));
        }
        if (address.province) {
            dispatch(setAddress(address));
        }
        if (offers.visionAccountNumber) {
            dispatch(setVisionAccountNumber(offers.visionAccountNumber));
        }
        if (application.branchNumber) {
            dispatch(setBranchNumber(application.branchNumber));
        }

        dispatch(setApplicationFlow(FlowEnum.RPOS));
    }

    return response?.data;
});

export const getLatestStepAsync = createAsyncThunk(
    '/step/latest',
    async (getLatestStepInfo: IGetLatestStep, { dispatch, getState }) => {
        const { application } = getState() as RootState;
        if (!application.isAuthenticated) throw new Error('Not Authorized');
        const { forceIsGlo, offerRequest } = getLatestStepInfo;
        await dispatch(getOffersAsync(offerRequest));
        const { offers } = getState() as RootState;

        if (!offers.offers || offerRequest.skipCheck) {
            if (forceIsGlo !== null) {
                dispatch(setIsGloOffer(forceIsGlo));
                dispatch(setApplicationFlow(forceIsGlo ? FlowEnum.GLO : FlowEnum.NONGLO));
            } else if (offers.isGlo !== null) {
                dispatch(setIsGloOffer(offers.isGlo));
                dispatch(setApplicationFlow(offers.isGlo ? FlowEnum.GLO : FlowEnum.NONGLO));
            }
            return null;
        }

        const response = await getLatestStep();
        if (response?.data?.state) {
            const { address, application, documentVerification, offers } = JSON.parse(response.data.state);
            if (application.channelCode) {
                dispatch(setChannelCode(application.channelCode));
            }
            if (application.adplacementId) {
                dispatch(setAdplacementId(application.adplacementId));
            }
            if (application.applicationNumber) {
                dispatch(setApplicationNumber(application.applicationNumber));
            }
            if (application.branchNumber) {
                dispatch(setBranchNumber(application.branchNumber));
            }
            if (address.province) {
                dispatch(setAddress(address));
            }

            if (offers.isGlo !== null) {
                if (forceIsGlo !== null) {
                    dispatch(setIsGloOffer(forceIsGlo));
                    dispatch(setApplicationFlow(forceIsGlo ? FlowEnum.GLO : FlowEnum.NONGLO));
                } else {
                    dispatch(setIsGloOffer(offers.isGlo));
                    dispatch(setApplicationFlow(offers.isGlo ? FlowEnum.GLO : FlowEnum.NONGLO));
                }
            }
            if (documentVerification.requiredDocuments) {
                dispatch(setRequiredDocuments(documentVerification.requiredDocuments));
            }
        } else if (offers.isGlo !== null) {
            dispatch(setApplicationFlow(offers.isGlo ? FlowEnum.GLO : FlowEnum.NONGLO));
        }

        return response?.data;
    }
);

export const updateLatestStep = async (state: IUserLastState): Promise<AxiosResponse | null> => {
    let response: AxiosResponse | null = null;

    try {
        response = await restAPI.put('/step/latest', state);
    } catch (error: any) {
        TrackJS.track(error);
    }
    return response;
};

export const updateLatestStepAsync = createAsyncThunk(
    '/step/latest',
    async (flinksLoginId: string | undefined, { dispatch, getState, rejectWithValue }) => {
        const { address, application, demographic, documentVerification, offers } = getState() as RootState;
        const applicationNumber = application.applicationNumber || '';
        const province = address.address.province || '';

        const nextStep = currentPage ? (currentFlow[currentPage] as StepNameEnum) : StepNameEnum.OFFER_PAGE;
        let latest = application.latestStep || ScreenUrlPathsEnum.OFFER_PAGE;
        try {
            if (
                isEmpty(applicationNumber) &&
                currentPage === StepNameEnum.ADDRESS_PAGE &&
                currentFlowEnum === FlowEnum.NONGLO
            ) {
                //Prevent from changing step if applicationNumber is null for NONGLO
                return;
            }
            let forceRedirect = true;
            //Update LatestStep only if nextStep is furthest
            if (!isFurthestStep(nextStep, findStepNameByURL(latest))) {
                dispatch(setLatestStep(nextStep));
                latest = ScreenUrlPathsEnum[nextStep];
                forceRedirect = false;
            }

            // be true if next step is smaller or equal to last step
            setCurrentStep(nextStep, forceRedirect);

            const latestStepName = findStepNameByURL(latest);

            let userState: IUserLastState = {
                state: {
                    address: {
                        province: province,
                    },
                    application: {
                        ...application,
                        applicationNumber,
                        connectedToFlinks: !!application.connectedToFlinks,
                        latestStep: latest,
                        visionAccountNumber: offers.visionAccountNumber,
                    },
                    offers: {
                        ...offers,
                        isGlo: offers.isGlo,
                    },
                },
                status:
                    latestStepName === StepNameEnum.THANK_YOU
                        ? ApplicationStatusEnum.COMPLETED
                        : ApplicationStatusEnum.PENDING,
                stepName: latestStepName,
            };

            if (currentFlowEnum === FlowEnum.RPOS) {
                userState = {
                    ...userState,
                    state: {
                        ...userState.state,
                        demographic: demographic.information,
                    },
                };
            }

            if (currentFlowEnum != FlowEnum.RPOS) {
                userState = {
                    ...userState,
                    flinksLoginId,
                    state: {
                        ...userState.state,
                        documentVerification: {
                            requiredDocuments: documentVerification.requiredDocuments,
                        },
                    },
                };
            }

            updateLatestStep(userState);

            return;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const createNonGloApplication = async (
    applicationRequest: INonGloApplicationRequest
): Promise<AxiosResponse<INonGloApplicationResponse> | null> => {
    let response: AxiosResponse<INonGloApplicationResponse> | null = null;

    try {
        response = await restAPI.post('/offer/start-loan-application', applicationRequest);
    } catch (error: any) {
        TrackJS.track(error);
    }

    return response;
};

export const createNonGloApplicationAsync = createAsyncThunk(
    '/application/createNonGloApplication',
    async (applicationRequest: INonGloApplicationRequest, { rejectWithValue }) => {
        const response = await createNonGloApplication(applicationRequest);

        if (response && response?.data) {
            return response?.data;
        }

        return rejectWithValue(response);
    }
);

const createRposApplication = async (
    request: IRposApplicationRequest
): Promise<AxiosResponse<IRposApplicationResponse> | null> => {
    let response: AxiosResponse<IRposApplicationResponse> | null = null;

    try {
        response = await restAPI.post('/offer/start-loan-application', request);
    } catch (error: any) {
        TrackJS.track(error);
    }

    return response;
};

export const createRposApplicationAsync = createAsyncThunk(
    '/application/createRposApplication',
    async (request: IRposApplicationRequest, { rejectWithValue }) => {
        const response = await createRposApplication(request);

        if (response && response?.data) {
            return response?.data;
        }

        return rejectWithValue(response);
    }
);
